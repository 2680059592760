<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card :isloading="cardloading">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <!-- <b-col sm="12" md="8">
                <form-select-multiple :disabled="is_all_item" @value-changed="name_category = $event.map(n => n.label)" ref="categorySelect" :rules="{required: !is_all_item}" url="v1/item_category_multi_select2" v-model="id_category" label="Kategori Item"></form-select-multiple>
              </b-col>
              <b-col sm="12" md="4">
                <form-checkbox @value-changed="allItemCheckbox" class="mt-2" v-model="is_all_item" label="Semua Item"></form-checkbox>
              </b-col>
              <b-col sm="12">
                <form-select-multiple :disabled="is_all_item" @value-changed="name_item = $event.map(n => n.label)" ref="itemSelect" :rules="{required: false}" :url="`v1/item_select2?id_category=${idCategoryParams}`" v-model="id_item" label="Item"></form-select-multiple>
              </b-col> -->
              <!-- <b-col sm="12">
                <form-select-multiple @value-changed="name_item_package = $event.map(n => n.label)" ref="itemPackageSelect" :rules="{required: false}" :url="`v1/item_package_select2?id_category=${idCategoryParams}`" v-model="id_item_package" label="Paket Item"></form-select-multiple>
              </b-col> -->
              <b-col sm="12" md="8">
                <form-select-multiple :disabled="is_all_partner" @value-changed="name_payment_partner = $event.map(n => n.label)" ref="partnerSelect" :rules="{required: !is_all_partner}" url="v1/payment_partner_select2" v-model="id_payment_partner" label="Mitra"></form-select-multiple>
              </b-col>
              <b-col sm="12" md="4">
                <form-checkbox @value-changed="allPartnerCheckbox" class="mt-2" v-model="is_all_partner" label="Semua Partner"></form-checkbox>
              </b-col>

              <b-col sm="12" md="8">
                <form-select-multiple @value-changed="name_tax = $event.map(n => n.label)" ref="taxSelect" :rules="{required: true}" url="v1/tax_select2" v-model="id_tax" label="Pajak"></form-select-multiple>
              </b-col>
              <!-- <b-col sm="12" md="8">
                <form-select-static-multiple @value-changed="name_type = $event.map(n => n.label)" :options="taxTypes" ref="typeSelect" :rules="{required: true}" v-model="id_type" label="Tipe"></form-select-static-multiple>
              </b-col> -->
              <b-col sm="12">
                <form-checkbox v-model="is_include" label="Include"></form-checkbox>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: false}" v-model="note" label="Catatan"></form-input>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'itemtaxes'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
// import FormSelectStaticMultiple from '@/my-components/FormSelectStaticMultiple.vue'
import FormSelectMultiple from '@/my-components/FormSelectMultiple.vue'
import FormCheckbox from '@/my-components/FormCheckbox.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import _ from 'lodash'

export default {
  components:{
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormCheckbox,
    FormSelectMultiple,
    // FormSelectStaticMultiple
  },
  data(){
    return {
      id: this.$route.params.id||null,
      code:"",
      is_active: true,
      isloading: false,
      cardloading: false,
      taxTypes:[
        {value:1,label:"Pembelian"},
        {value:2,label:"Penjualan"},
      ],
      is_all_item: true,
      id_category: [],
      name_category: [],
      id_item: [],
      name_item: [],
      id_item_package: [],
      name_item_package: [],
      is_all_partner: false,
      id_payment_partner: [],
      name_payment_partner: [],
      id_tax: [],
      name_tax: [],
      is_include: true,
      id_type: [2],
      name_type: ["Penjualan"],
      note: ""
    }
  },
  watch:{
    id_category(newvalue,oldvalue){
      if (newvalue.length<oldvalue.length) {
        this.id_item = []
        this.name_item = []
        this.id_item_package = []
        this.name_item_package = []
      }
    }
  },
  computed:{
    idCategoryParams(){
      if(this.id_category.length<1) return ''
      else return _.toString(this.id_category)
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'itemtaxes/store',
      dispatchShow: 'itemtaxes/show',
      dispatchUpdate: 'itemtaxes/update',
    }),
    allItemCheckbox(){
      this.id_category = []
      this.name_category = []
      this.id_item = []
      this.name_item = []
      this.id_item_package = []
      this.name_item_package = []
    },
    allPartnerCheckbox(){
      this.id_payment_partner = []
      this.name_payment_partner = []
    },
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.code = _.toString(data.name_tax)+` (${_.toString(data.name_type)})`
      this.id_category = data.id_category
      this.name_category = data.name_category
      this.id_item = data.id_item
      this.id_item_package = data.id_item_package
      this.name_item = data.name_item
      this.name_item_package = data.name_item_package
      this.id_payment_partner = data.id_payment_partner
      this.name_payment_partner = data.name_payment_partner
      this.id_tax = data.id_tax
      this.name_tax = data.name_tax
      this.id_type = data.id_type
      this.name_type = data.name_type
      this.is_all_item = data.is_all_item ? true : false
      this.is_all_partner = data.is_all_partner ? true : false
      this.is_include = data.is_include ? true : false
      this.note = data.note
      /*
      for (let i = 0; i < data.id_category.length; i++) {
        const value = data.id_category[i];
        const label = data.name_category[i];
        this.$refs.categorySelect.initOption([{value:value,label:label}])
      }
      */
     /*
      for (let i = 0; i < data.id_item.length; i++) {
        const value = data.id_item[i];
        const label = data.name_item[i];
        this.$refs.itemSelect.initOption([{value:value,label:label}])
      }
      */
     /*
      for (let i = 0; i < data.id_item_package.length; i++) {
        const value = data.id_item_package[i];
        const label = data.name_item_package[i];
        this.$refs.itemPackageSelect.initOption([{value:value,label:label}])
      }
      */
      for (let i = 0; i < data.id_payment_partner.length; i++) {
        const value = data.id_payment_partner[i];
        const label = data.name_payment_partner[i];
        this.$refs.partnerSelect.initOption([{value:value,label:label}])
      }
      for (let i = 0; i < data.id_tax.length; i++) {
        const value = data.id_tax[i];
        const label = data.name_tax[i];
        this.$refs.taxSelect.initOption([{value:value,label:label}])
      }
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,code,is_all_item,id_category,name_category,id_item,id_item_package,name_item,name_item_package,id_payment_partner,name_payment_partner,id_tax,name_tax,id_type,name_type,note} = this
      let params = {id,code,is_all_item,id_category,name_category,id_item,id_item_package,name_item,name_item_package,id_payment_partner,name_payment_partner,id_tax,name_tax,id_type,name_type,note}
      params.is_all_item = this.is_all_item ? 1 : 0
      params.is_all_partner = this.is_all_partner ? 1 : 0
      params.is_include = this.is_include ? 1 : 0
      try {
        if (this.id) await this.dispatchUpdate(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'itemtaxes'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style>

</style>